import client from "@/api/api-client"
import { computed } from "vue"
import { useQuery } from "vue-query"

export function useUser({ id, companyId, registered }, enabled) {
  const { data, refetch } = useQuery(
    ["user", registered],
    async () => {
      const { data } = await client.get(`/crm/user/${id.value}/company/${companyId.value}`)
      return data
    },
    { enabled }
  )

  const statusCode = computed(() => data.value?.statusCode)
  const user = computed(() => (data.value ? data.value.data.userCrm : {}))
  const template = computed(() => (data.value ? data.value.data.template : {}))
  const announcements = computed(() => (data.value ? data.value.data.announcements : []))

  return { user, template, announcements, statusCode, refetch }
}
