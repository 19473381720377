import { StorageSerializers, useLocalStorage } from "@vueuse/core"
import { defineStore } from "pinia"
import { ref } from "vue"

export type User = {
  id: number
  lineUserId: string
  firstName: string
  lastName: string
  gender: string
  birthdate: string
  phoneNumber: string
  lineDisplayName: string
  email: string
  pdpa: boolean
  news: boolean
  point: number
  profileImgUrl: string
  zipcode: string
  province: string
  amphoe: string
  district: string
  address: string
  companyId: number
  createdDate: string
  updatedDate: string
  shopId: number
  shopCrm?: Shop
}

export type Shop = {
  name: string
  point: number
}

export const useUserStore = defineStore("user", () => {
  const user = useLocalStorage<User>(
    "user",
    StorageSerializers.object.read(localStorage.getItem("user")),
    { serializer: StorageSerializers.object }
  )
  const qrCode = useLocalStorage<string>("code", localStorage.getItem("code"))
  const registered = ref(false)
  const initialScanCodeUsed = ref(false)

  function setUser(value: User) {
    user.value = value
  }

  function setCode(value: string) {
    qrCode.value = value
  }

  function setRegistered(value: boolean) {
    registered.value = value
  }

  function setUserPoint(value: number) {
    const newUser = { ...user.value }
    newUser.point = value
    setUser(newUser)
  }

  function setShopPoint(point: number) {
    user.value.shopCrm.point = point
  }

  return {
    user,
    qrCode,
    registered,
    initialScanCodeUsed,
    setUser,
    setCode,
    setRegistered,
    setUserPoint,
    setShopPoint,
  }
})
