export const SUCCESS = 200
export const READY_USE_POINT = 201
export const NOT_FOUND = 404
export const SERVER_ERROR = 500
export const PHONE_NUMBER_EXISTS = 501
export const EMAIL_EXISTS = 502
export const EMAIL_PHONE_EXISTS = 503
export const USER_NOT_EXISTS = 1001
export const POINT_NOT_ENOUGH = 1003
export const OVER_LIMIT = 1004
export const CAN_NOT_REDEEM = 1005
export const NO_ADDRESS = 1006
export const INVALID_CODE = 1007
export const INVALID_POINT = 1008
export const ALREADY_SCANNED = 1009
