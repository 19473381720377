import { getLiffProfile } from "@/api/line"
import { defineStore } from "pinia"
import VConsole from "vconsole"
import { computed, ref } from "vue"

type LineProfile = {
  userId: string
  displayName: string
  pictureUrl?: string
  statusMessage?: string
}

const mockUser = {
  // userId: "Uf914eb781acbb04d0c911d51862bbd5f",
  userId: import.meta.env.VITE_LINE_MOCK_USER_ID || "Uf914eb781acbb04d0c911d51862bbd5f",
  displayName: import.meta.env.VITE_LINE_MOCK_DISPLAY_NAME || "aomdogma👾",
  pictureUrl:
    import.meta.env.VITE_LINE_MOCK_PICTURE_URL ||
    "https://profile.line-scdn.net/0hhA_ngD6MN25yFyT7zY9JEQJHNARRZm58WHd_WkBAbwwaL3ExX3AvDxUWbgwcInUwCnF6CRVEOV9-BEAIbEHLWnUnaVlLIXc_V3dxiQ",
}

const DEBUGGING_USERS = ["Uc49a1a4875d889d46e49b0ac0fcefcf55", "Uf914eb781acbb04d0c911d51862bbd5f"]

export const useLineUserStore = defineStore("lineProfile", () => {
  const liffId = ref<string>(null)
  const lineUser = ref<LineProfile>(null)
  const lineUserId = computed(() => lineUser.value?.userId)
  const hasLineUser = computed(() => lineUser.value !== null)

  async function fetchLineUser() {
    if (lineUser.value) {
      return
    }

    lineUser.value = await getLiffProfile()

    // DEBUGGING
    if (DEBUGGING_USERS.includes(lineUser.value.userId)) {
      new VConsole()
    }
  }

  function setLiffId(newLiffId: string) {
    liffId.value = newLiffId
  }

  return { liffId, lineUser, lineUserId, hasLineUser, fetchLineUser, setLiffId }
})
