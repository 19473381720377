import { createApp } from "vue"
import { createPinia } from "pinia"
import { VueQueryPlugin } from "vue-query"
import VueScrollPicker from "vue-scroll-picker"
import VCalendar from "v-calendar"
import vSelect from "vue-select"
import SvgIcon from "@jamescoyle/vue-icon"
import * as Sentry from "@sentry/vue"

import "vue-scroll-picker/lib/style.css"
import "@/style.scss"
import "./validate-rules"
import "bottom-sheet-vue3/css/style.css"
import "v-calendar/dist/style.css"
import "vue-select/dist/vue-select.css"

import router from "./router"
import App from "./App.vue"

import { showConsoleLog } from "@/config/env"
import VConsole from "vconsole"

if (showConsoleLog() === "true") {
  new VConsole()
}

const pinia = createPinia()
const app = createApp(App)

app.component("VSelect", vSelect)

app.use(pinia)
app.use(VueQueryPlugin)
app.use(router)
app.use(VueScrollPicker)
app.component("SvgIcon", SvgIcon)
app.use(VCalendar, {})

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    app,
    dsn: "https://ca317ac5f4a4a3cd4d2348fda7824678@o4506931515752448.ingest.us.sentry.io/4506931516866560",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["https://thavorn-prod-api-bx6x5ne5gq-as.a.run.app"],
    // Session Replay
    replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const originalFetch = window.fetch

async function customFetch(url, options) {
  if (
    url.toString().startsWith("https://liffsdk.line-scdn.net/xlt/") &&
    url.toString().endsWith(".json")
  ) {
    url = url + "?ts=" + Math.random()

    try {
      const response = await originalFetch(url, options)
      return response
    } catch (error) {
      console.log("fetch line manifest error use develop manifest", error)
      try {
        return originalFetch("https://develop.thavorn-crm.pages.dev/manifest.json", options)
      } catch (error) {
        console.log("fetch crm manifest error use local manifest", error)
        return originalFetch("/manifest.json", options)
      }
    }
  } else {
    return originalFetch(url, options)
  }
}

window.fetch = customFetch

app.mount("#app")
