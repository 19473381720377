import liff from "@line/liff"
import { isLineMock } from "./env"

export let isLiffInitialized = false

const retryLimit = 1
let retryCount = 0

export async function initLiff(liffId: string) {
  if (!isLineMock()) {
    try {
      isLiffInitialized = true

      await liff.init({
        liffId: liffId,
        withLoginOnExternalBrowser: true,
      })
    } catch (err) {
      console.log(err.code, err.message)

      if (retryCount < retryLimit) {
        retryCount++
        await initLiff(liffId)
      } else {
        // refresh on reach retry limit
        // window.location.href = window.location.href
      }
    }
  }
}
