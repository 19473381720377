import { isLineMock } from "@/config/env"
import liff from "@line/liff"

const mockUser = {
  // userId: "Uf914eb781acbb04d0c911d51862bbd5f",
  userId: import.meta.env.VITE_LINE_MOCK_USER_ID || "Uf914eb781acbb04d0c911d51862bbd5f",
  displayName: import.meta.env.VITE_LINE_MOCK_DISPLAY_NAME || "aomdogma👾",
  pictureUrl:
    import.meta.env.VITE_LINE_MOCK_PICTURE_URL ||
    "https://profile.line-scdn.net/0hhA_ngD6MN25yFyT7zY9JEQJHNARRZm58WHd_WkBAbwwaL3ExX3AvDxUWbgwcInUwCnF6CRVEOV9-BEAIbEHLWnUnaVlLIXc_V3dxiQ",
}

export function getLiffAccessToken() {
  if (isLineMock()) {
    return import.meta.env.VITE_LINE_MOCK_ACCESS_TOKEN || "ACCESS_TOKEN"
  } else {
    return liff.getAccessToken()
  }
}

export async function getLiffProfile() {
  if (isLineMock()) {
    return mockUser
  } else {
    return liff.getProfile()
  }
}

export function scanCode() {
  if (isLineMock()) {
    return { value: "https://thavorn-mobile-web.web.app/29I6FG2J3H4O" }
    // return { value: "https://thavorn-mobile-web.web.app/yFX6EsCEyzcj" }
  }
  return liff.scanCodeV2()
}
