import { Announcement } from "@/types/announcement"
import { Template } from "@/types/template"
import { useLocalStorage } from "@vueuse/core"
import { defineStore } from "pinia"
import { reactive } from "vue"

export const useCompanyStore = defineStore("company", () => {
  const template = reactive<Partial<Template>>({})
  const companyId = useLocalStorage<string>("companyId", localStorage.getItem("companyId"))
  const announcements = reactive<Announcement[]>([])

  function setTemplate(value: Template) {
    Object.assign(template, value)
  }

  function setCompanyId(value: string) {
    companyId.value = value
  }

  function setAnnouncements(value: Announcement[]) {
    announcements.push(...value)
  }

  return { template, companyId, announcements, setTemplate, setCompanyId, setAnnouncements }
})
