<script setup lang="ts">
import { storeToRefs } from "pinia"
import { computed, onMounted, watch } from "vue"
import { useRouter } from "vue-router"
import client from "./api/api-client"
import { initLiff, isLiffInitialized } from "./config/liff"
import { USER_NOT_EXISTS } from "./constant/statusCode"
import { useUser } from "./hooks/useUser"
import { useCompanyStore } from "./stores/company-store"
import { useLineUserStore } from "./stores/line-user-store"
import { useUserStore } from "./stores/user-store"

const router = useRouter()
const companyStore = useCompanyStore()
const { companyId } = storeToRefs(companyStore)

const lineStore = useLineUserStore()
const { lineUserId, hasLineUser } = storeToRefs(lineStore)

const userStore = useUserStore()
const { registered } = storeToRefs(userStore)
const startFetchuser = computed(() => hasLineUser.value && companyId.value !== null)

const { user, template, announcements, statusCode, refetch } = useUser(
  { id: lineUserId, companyId, registered },
  startFetchuser
)

const maxRetry = 3
let retryCount = 0

onMounted(() => {
  const interval = setInterval(async () => {
    if (retryCount > maxRetry) {
      clearInterval(interval)
      throw new Error("max retry reached")
    }

    retryCount++

    console.log("start fetching user data")
    if (!isLiffInitialized) {
      console.log("liff not initialized")

      const { data: liffId } = await client.get(`/crm/company/${companyId}/liffId`)

      if (liffId.statusCode !== 200) {
        return router.push("not-found")
      }

      console.log("liffId", liffId.data)
      lineStore.setLiffId(liffId.data)

      await initLiff(liffId.data)

      console.log("liff initialized")
    }

    if (!lineUserId.value) {
      console.log("fetching line user")
      await lineStore.fetchLineUser()

      console.log("fetching user")
      const result = await refetch.value()

      console.log("fetched user result", result.data, result.error)
    } else {
      console.log("line user already fetched")
      clearInterval(interval)
    }
  }, 1000 * 10)
})

watch(
  template,
  () => {
    if (template.value) {
      companyStore.setTemplate(template.value)
      document.documentElement.style.setProperty("--color-primary", hexToRgb(template.value.color))
    }
  },
  { immediate: true }
)

watch(
  announcements,
  () => {
    if (announcements.value.length) {
      companyStore.setAnnouncements(announcements.value)
    }
  },
  { immediate: true }
)

watch(
  statusCode,
  () => {
    if (statusCode.value) {
      redirectOnNonExistsUser()
    }
  },
  { immediate: true }
)

watch(
  user,
  () => {
    if (user.value) {
      userStore.setUser(user.value)
      router.push("/home")
    }
  },
  { immediate: true }
)

async function redirectOnNonExistsUser() {
  if (statusCode.value === USER_NOT_EXISTS) {
    router.push("/register")
  }
}

function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? `${parseInt(result[1], 16)} ${parseInt(result[2], 16)} ${parseInt(result[3], 16)}`
    : ""
}
</script>

<template>
  <RouterView v-slot="{ Component }">
    <Component :is="Component"></Component>
  </RouterView>
</template>
