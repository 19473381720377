import { email, required, digits, regex } from "@vee-validate/rules"
import { defineRule } from "vee-validate"

defineRule("required", value => (required(value) ? true : "กรอกข้อมูล"))
defineRule("email", value => (email(value) ? true : "รูปแบบอีเมลไม่ถูกต้อง"))
defineRule("digits", (value, param) => (digits(value, param) ? true : "หมายเลขโทรศัพท์ไม่ถูกต้อง"))
defineRule("regexName", value =>
  regex(value, { regex: /[ก-๏A-Za-z\s]+$/ })
    ? true
    : "กรุณาใส่ชื่อให้ถูกต้อง ไม่สามารถเป็นอักขระพิเศษได้"
)
defineRule("regexLastName", value =>
  regex(value, { regex: /[ก-๏A-Za-z\s]+$/ })
    ? true
    : "กรุณาใส่นามสกุลให้ถูกต้อง ไม่สามารถเป็นอักขระพิเศษได้"
)
