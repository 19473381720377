import client from "@/api/api-client"
import { useCompanyStore } from "./../stores/company-store"

import { initLiff } from "@/config/liff"
import { useLineUserStore } from "@/stores/line-user-store"
import type { Router } from "vue-router"

export const useLineMiddleware = (router: Router) => {
  router.beforeEach(async (to, from, next) => {
    const lineStore = useLineUserStore()

    if (!to.meta.requiresLineUser || lineStore.liffId) {
      return next()
    }

    const { companyId } = useCompanyStore()

    if (!companyId) {
      return next({ path: "not-found" })
    }

    if (!lineStore.liffId) {
      const { data: liffId } = await client.get(`/crm/company/${companyId}/liffId`)

      if (liffId.statusCode !== 200) {
        return next({ path: "not-found" })
      }

      lineStore.setLiffId(liffId.data)

      await initLiff(liffId.data)

      await lineStore.fetchLineUser()
    }

    return next()
  })
}
