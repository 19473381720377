import { isLiffInitialized } from "@/config/liff"
import liff from "@line/liff"
import axios from "axios"
import { getLiffAccessToken } from "./line"
import * as Sentry from "@sentry/vue"

const { VITE_BACKEND_URL } = import.meta.env

let accessToken = ""

const client = axios.create({
  baseURL: VITE_BACKEND_URL,
  // baseURL: "https://thavorn-uat-api-bx6x5ne5gq-as.a.run.app",
})

client.interceptors.request.use(async config => {
  config.headers["X-VERSION"] = __APP_VERSION__

  if (isLiffInitialized) {
    await liff.ready
    const liffAccessToken = getLiffAccessToken()

    if (liffAccessToken) {
      accessToken = liffAccessToken
    }

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    } else {
      try {
        console.error("no liff access token", liff.getContext())
        config.headers["X-LIFF-CONTEXT"] = JSON.stringify(liff.getContext())
      } catch (err) {}
    }
  }

  return config
})

export default client
