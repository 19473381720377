import { useUserStore } from "./../stores/user-store"
import { useCompanyStore } from "./../stores/company-store"
import { getLineQueryParams } from "@/utils/line-query-params"
import type { Router } from "vue-router"

export const useStoredQueryMiddleware = (router: Router) => {
  router.beforeEach(() => {
    const queryParams = getLineQueryParams()
    const companyId = queryParams.get("companyId")
    const code = queryParams.get("foundCode")

    console.log("code", code)

    if (companyId !== null) {
      const companyStore = useCompanyStore()
      companyStore.setCompanyId(companyId)
    }

    if (code !== null) {
      const userStore = useUserStore()
      userStore.setCode(code)
      console.log("set code")
    }
  })
}
